import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';

import { Button, Flex, Text, Box, Card, Grid } from '@makerdao/ui-components';
import Loader from '../components/Loader';
import { MkrAmt } from '../components/modals/shared/styles';
import { cutMiddle, formatRound } from '../utils/misc';
import { modalOpen } from '../reducers/modal';
import FreeAllProxy from '../components/modals/FreeAllProxy';
import FreeAllDirect from '../components/modals/FreeAllDirect';
import RemoveMkrAllowance from '../components/modals/RemoveAllowance';
import { activeCanVote, getActiveVotingFor } from '../reducers/accounts';
import { getActiveAccount } from '../reducers/accounts';
import { ethScanLink } from '../utils/ethereum';
import { ReactComponent as NotificationIcon } from '../imgs/notification-icon.svg';

const BorderBottom = styled.div`
  height: 1px;
  background: #dbdddd;
  position: absolute;
  top: 208px;
  left: 0;
  width: 100%;
`;

const CenteredNotificationIcon = styled(NotificationIcon)`
  margin: 5px;
  margin-top: 4px;
  margin-bottom: -4px;
`;

function ExploitNotice() {
  return (
    <>
      <Box m="s" style={{ color: 'black' }}>
        <CenteredNotificationIcon />
        <Text fontWeight="bold">
          Due to the recent discovery of a potential exploit in the Maker
          Governance Contract (DSChief), all users are requested to check their
          MKR balances and withdraw any MKR deposited into one of the voting
          contracts back to their wallet. You can read more{' '}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://redd.it/ble9j1"
          >
            here
          </a>
          .
        </Text>
      </Box>
      <BorderBottom />
    </>
  );
}

function getColdWalletAddress(activeAccount) {
  if (!activeAccount || !activeAccount.hasProxy) return null;
  if (activeAccount.proxyRole === 'cold') return activeAccount.address;
  return activeAccount.proxy.linkedAccount.address;
}

const Timeline = ({ fetching, activeAccount, modalOpen, network }) => {
  if (fetching)
    return (
      <Loader
        size={20}
        mt={125}
        mb={200}
        color="header"
        background="background"
      />
    );

  if (activeAccount === undefined)
    return (
      <Box>
        <ExploitNotice />
        <Grid
          gridRowGap="22px"
          justifyContent="center"
          m="auto"
          style={{ marginTop: '3em' }}
        >
          <Box>
            <h2>1) Connect the wallet that you used for voting</h2>
          </Box>
          <Box>
            <h2>2) See your balances</h2>
          </Box>
          <Box>
            <h2>3) Withdraw any MKR back to your wallet</h2>
          </Box>
        </Grid>
      </Box>
    );

  const hasMkrLockedDirectly = parseFloat(activeAccount.directDeposits) > 0;
  const hasMkrLockedViaProxy =
    activeAccount.hasProxy && parseFloat(activeAccount.proxy.proxyDeposts) > 0;
  const hasGivenProxyInfMkrApprovals =
    activeAccount.hasProxy && activeAccount.proxy.hasInfMkrApproval;
  const isColdWallet =
    activeAccount.hasProxy && activeAccount.proxyRole === 'cold';

  if (
    !hasMkrLockedDirectly &&
    !hasMkrLockedViaProxy &&
    !hasGivenProxyInfMkrApprovals
  )
    return (
      <Box>
        <ExploitNotice />
        <Box
          style={{ fontSize: '1.25em', textAlign: 'center', marginTop: '8em' }}
        >
          <Text>
            You're all set! No further action necessary. Please visit us at{' '}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://chat.makerdao.com/channel/help"
            >
              chat.makerdao.com
            </a>{' '}
            if you have any questions
          </Text>
        </Box>
      </Box>
    );

  return (
    <Box>
      <ExploitNotice />
      <Grid mt="xl" gridColumnGap="m" gridRowGap="xl">
        <Card p="l" width="100%">
          <Grid
            gridTemplateColumns={['2fr 1fr']}
            gridRowGap="m"
            gridColumnGap="m"
          >
            <Box>
              <Box>
                <h2>Vote Proxy Contract</h2>
              </Box>
              <Box mt="s">
                <Box>
                  <Text>
                    If you have been using the voting application at
                    vote.makerdao.com then your MKR will likely be deposited via
                    a Vote Proxy.
                  </Text>
                </Box>

                <Box mt="s">
                  <Text>
                    Any MKR here will be withdrawn back to your cold wallet{' '}
                    {activeAccount.hasProxy ? (
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={ethScanLink(
                          getColdWalletAddress(activeAccount),
                          network
                        )}
                      >
                        {cutMiddle(getColdWalletAddress(activeAccount), 6, 4)}
                      </a>
                    ) : null}
                  </Text>
                </Box>

                <Box>
                  <Box mt="l">
                    <Box>
                      <Text>
                        To completely decommision your vote proxy, it is good
                        practice to remove all MKR token approvals it still has.{' '}
                        {isColdWallet ? (
                          'Press the button on the right to initiate this transaction.'
                        ) : (
                          <Text style={{ fontStyle: 'oblique' }}>
                            This must be done from your cold wallet.
                          </Text>
                        )}
                      </Text>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
            <Flex
              flexDirection="column"
              textAlign="center"
              m="auto 0 auto auto"
            >
              <Box mb="xs" style={{ marginTop: '20px' }}>
                <MkrAmt>
                  {activeAccount.hasProxy
                    ? formatRound(activeAccount.proxy.proxyDeposts)
                    : '0.00'}{' '}
                </MkrAmt>
              </Box>
              <Button
                width="230px"
                onClick={() => modalOpen(FreeAllProxy)}
                disabled={!hasMkrLockedViaProxy}
              >
                WITHDRAW
              </Button>
              <Button
                style={{ marginTop: '70px', marginBottom: '10px' }}
                width="230px"
                disabled={!hasGivenProxyInfMkrApprovals || !isColdWallet}
                onClick={() => modalOpen(RemoveMkrAllowance)}
              >
                REMOVE ALLOWANCE
              </Button>
            </Flex>
          </Grid>
        </Card>

        <Card p="l" width="100%">
          <Grid
            gridTemplateColumns={['2fr 1fr']}
            gridRowGap="m"
            gridColumnGap="m"
          >
            <Box>
              <Box>
                <h2>DSChief Balance</h2>
              </Box>
              <Box mt="s">
                <Box>
                  <Text>
                    If you have been using the command line or the voting app at
                    chief.makerdao.com, your MKR will likely be deposited
                    directly in DSChief.
                  </Text>
                </Box>
                {parseFloat(activeAccount.directDeposits) > 0 ? (
                  <Box mt="s">
                    <Text>
                      Any MKR here will be withdrawn back to your connected
                      wallet{' '}
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={ethScanLink(activeAccount.address, network)}
                      >
                        {cutMiddle(activeAccount.address, 6, 4)}
                      </a>
                    </Text>
                  </Box>
                ) : null}
              </Box>
            </Box>
            <Flex
              flexDirection="column"
              textAlign="center"
              m="auto 0 auto auto"
            >
              <Box mb="xs">
                <MkrAmt>{formatRound(activeAccount.directDeposits)} </MkrAmt>
              </Box>
              <Button
                width="230px"
                onClick={() => modalOpen(FreeAllDirect)}
                disabled={!hasMkrLockedDirectly}
              >
                WITHDRAW
              </Button>
            </Flex>
          </Grid>
        </Card>
      </Grid>
    </Box>
  );
};

const reduxProps = (
  { proposals, accounts, hat, approvals, metamask },
  { signaling }
) => {
  return {
    network: metamask.network,
    activeAccount: getActiveAccount({ accounts }),
    metamaskFetching: metamask.fetching,
    hat,
    approvals,
    proposals: proposals.filter(p => !!p.govVote === !!signaling),
    canVote: activeCanVote({ accounts }),
    fetching: accounts.fetching,
    votingFor: getActiveVotingFor({ accounts })
  };
};

export default connect(
  reduxProps,
  { modalOpen }
)(Timeline);
