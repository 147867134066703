import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';

import Button from '../Button';
import { add, formatRound } from '../../utils/misc';
import { getActiveAccount } from '../../reducers/accounts';
import { modalClose } from '../../reducers/modal';
import { freeAllDirect } from '../../reducers/migration';

import {
  StyledTitle,
  StyledBlurb,
  StyledTop,
  MkrAmt,
  VoteImpact,
  VoteImpactHeading
} from './shared/styles';
import TransactionModal from './shared/InitiateTransaction';

// const headingColor = '#212536';
const borderColor = '#DFE1E3';

class FreeAllDirect extends Component {
  render() {
    const {
      freeAllTxHash,
      freeAllTxStatus,
      activeAccount,
      modalClose
    } = this.props;

    return (
      <TransactionModal
        txHash={freeAllTxHash}
        txStatus={freeAllTxStatus}
        account={activeAccount}
        txPurpose="This transaction is to return all MKR to your connected wallet"
        onComplete={modalClose}
      >
        {onNext => {
          return (
            <Fragment>
              <StyledTop>
                <StyledTitle>Confirmation</StyledTitle>
              </StyledTop>
              <VoteImpact style={{ marginTop: '4em' }}>
                <div
                  style={{
                    width: '100%',
                    padding: '8px 30px'
                  }}
                >
                  <VoteImpactHeading>
                    current connected wallet balance
                  </VoteImpactHeading>
                  <MkrAmt>{formatRound(activeAccount.mkrBalance)}</MkrAmt>
                </div>
                <div
                  style={{
                    width: '100%',
                    padding: '8px 30px',
                    borderLeft: `1px solid ${borderColor}`
                  }}
                >
                  <VoteImpactHeading>
                    balance after withdrawal
                  </VoteImpactHeading>
                  <MkrAmt>
                    {formatRound(
                      add(
                        activeAccount.mkrBalance,
                        activeAccount.directDeposits
                      )
                    )}
                  </MkrAmt>
                </div>
              </VoteImpact>
              <StyledBlurb
                style={{ textAlign: 'center', margin: '2em 0px 0.5em' }}
              >
                This transaction is to return all MKR to your connected wallet
              </StyledBlurb>
              <div
                style={{
                  float: 'right',
                  marginTop: '18px'
                }}
              >
                <Button
                  slim
                  onClick={() => {
                    this.props.freeAllDirect();
                    onNext();
                  }}
                >
                  Confirm
                </Button>
              </div>
            </Fragment>
          );
        }}
      </TransactionModal>
    );
  }
}

FreeAllDirect.defaultProps = {
  freeAllTxHash: '',
  freeAllTxStatus: ''
};

export default connect(
  state => ({
    activeAccount: getActiveAccount(state),
    freeAllTxHash: state.migration.freeAllDirectTxHash,
    freeAllTxStatus: state.migration.freeAllDirectTxStatus
  }),
  { modalClose, freeAllDirect }
)(FreeAllDirect);
