import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';

import Button from '../Button';
import { getActiveAccount } from '../../reducers/accounts';
import { modalClose } from '../../reducers/modal';
import { removeAllMkrAllowance } from '../../reducers/migration';

import { StyledTitle, StyledBlurb, StyledTop } from './shared/styles';
import TransactionModal from './shared/InitiateTransaction';

class RemoveAllowance extends Component {
  render() {
    const {
      removeAllowanceProxyTxHash,
      removeAllowanceProxyTxStatus,
      activeAccount,
      modalClose
    } = this.props;

    return (
      <TransactionModal
        txHash={removeAllowanceProxyTxHash}
        txStatus={removeAllowanceProxyTxStatus}
        account={activeAccount}
        txPurpose="This transaction is to remove all MKR token allowances from your vote proxy"
        onComplete={modalClose}
      >
        {onNext => {
          return (
            <Fragment>
              <StyledTop>
                <StyledTitle>Confirmation</StyledTitle>
              </StyledTop>
              <StyledBlurb
                style={{
                  textAlign: 'center',
                  width: '75%',
                  margin: '3em auto 1em auto'
                }}
              >
                This transaction is to remove all MKR token allowances from your
                vote proxy
              </StyledBlurb>
              <div
                style={{
                  float: 'right',
                  marginTop: '18px'
                }}
              >
                <Button
                  slim
                  onClick={() => {
                    this.props.removeAllMkrAllowance();
                    onNext();
                  }}
                >
                  Confirm
                </Button>
              </div>
            </Fragment>
          );
        }}
      </TransactionModal>
    );
  }
}

RemoveAllowance.defaultProps = {
  removeAllowanceProxyTxHash: '',
  removeAllowanceProxyTxStatus: ''
};

export default connect(
  state => ({
    activeAccount: getActiveAccount(state),
    removeAllowanceProxyTxHash: state.migration.removeAllowanceProxyTxHash,
    removeAllowanceProxyTxStatus: state.migration.removeAllowanceProxyTxStatus
  }),
  { modalClose, removeAllMkrAllowance }
)(RemoveAllowance);
