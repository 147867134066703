import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { withRouter } from 'react-router';
import { Header, Footer } from '@makerdao/ui-components';
import { colors } from '../theme';
import { onboardingOpen } from '../reducers/onboarding';
import { modalOpen } from '../reducers/modal';
import Modals from '../components/modals';
import Onboarding from '../components/Onboarding';
import Loader from '../components/Loader';
import AccountBox from '../components/AccountBox';
import Toasts from '../components/Toasts';
import NetworkIndicator from '../components/NetworkIndicator';

const StyledLayout = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  min-width: 1200px;
`;

const AppWrapper = styled.div`
  min-height: 400px;
  padding: 0px 16px;
`;

const StyledColumn = styled.div`
  align-items: center;
  max-width: 1140px;
  margin: auto;
`;

const StyledHeader = styled.div`
  margin-top: -1px;
  width: 100%;
  display: block;
  align-items: center;
  background-color: rgb(${colors.header});
  color: rgb(${colors.white});
`;

const HeaderBottom = styled.div`
  width: 100%;
`;

const HeaderBottomContent = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: 1140px;
  padding: 16px 0;
  align-items: center;
  margin: 0 auto;
`;

const StyledContent = styled.div`
  width: 100%;
`;

const Flex = styled.div`
  display: flex;
  align-items: center;
`;

const Padding = styled.div`
  height: 25px;
`;

const BorderLine = styled.div`
  height: 1px;
  background-color: #445162;
`;

const NetworkNotification = styled.div`
  color: ${({ theme }) => theme.text.header_dim};
  align-self: center;
  font-weight: 500;
`;

const NoContent = styled.div`
  font-size: 24px;
  font-weight: bold;
  margin-top: 200px;
  display: flex;
  justify-content: center;
  margin-bottom: 350px;
  font-style: oblique;
`;

const BaseLayout = ({
  children,
  network,
  onboardingOpen,
  modalOpen,
  metamaskFetching,
  proposalsAvailable,
  accountsFetching,
  wrongNetwork,
  onboardingState,
  location
}) => {
  const childrenShouldMount =
    !metamaskFetching && proposalsAvailable && !wrongNetwork;
  const noContentMsg = wrongNetwork ? (
    'Please switch network to Kovan or Mainnet'
  ) : (
    <Loader
      size={20}
      mt={125}
      mb={200}
      color="header"
      background="background"
    />
  );
  return (
    <StyledLayout>
      <StyledHeader>
        <Header />
        <BorderLine />
        <HeaderBottom>
          <HeaderBottomContent>
            <div style={{ display: 'flex', paddingLeft: '2.5rem' }}>
              <NavLink
                style={{ color: 'white' }}
                to={{ pathname: '/', search: location.search }}
              >
                Governance
              </NavLink>
              <NetworkNotification style={{ marginLeft: '16px' }}>
                {childrenShouldMount && <NetworkIndicator network={network} />}
              </NetworkNotification>
            </div>
            <Flex>
              <AccountBox fetching={!wrongNetwork && metamaskFetching} />
            </Flex>
          </HeaderBottomContent>
        </HeaderBottom>
      </StyledHeader>
      <AppWrapper>
        <StyledColumn>
          <StyledContent>
            {childrenShouldMount ? (
              children
            ) : (
              <NoContent>{noContentMsg}</NoContent>
            )}
          </StyledContent>
          <Padding />
        </StyledColumn>
      </AppWrapper>
      <Modals />
      <Toasts />
      <Footer />
      <Onboarding />
    </StyledLayout>
  );
};

BaseLayout.propTypes = {
  children: PropTypes.node.isRequired
};

const reduxProps = (
  { metamask, proposals, accounts, hat, onboarding },
  { location }
) => ({
  metamaskFetching: metamask.fetching,
  wrongNetwork: metamask.wrongNetwork,
  network: metamask.network,
  accountsFetching: accounts.fetching,
  proposalsAvailable: proposals.length > 0 && !hat.fetching,
  onboardingState: onboarding.state,
  location
});

export default withRouter(
  connect(
    reduxProps,
    { onboardingOpen, modalOpen }
  )(BaseLayout)
);
