import { createReducer } from '../utils/redux';
import { getAccount, addAccounts } from './accounts';
import { TransactionStatus } from '../utils/constants';
import { addToastWithTimeout, ToastTypes } from './toasts';

export const FREE_ALL_PROXY_REQUEST = 'migration/FREE_ALL_PROXY_REQUEST';
export const FREE_ALL_PROXY_SENT = 'migration/FREE_ALL_PROXY_SENT';
export const FREE_ALL_PROXY_SUCCESS = 'migration/FREE_ALL_PROXY_SUCCESS';
export const FREE_ALL_PROXY_FAILURE = 'migration/FREE_ALL_PROXY_FAILURE';

export const FREE_ALL_DIRECT_REQUEST = 'migration/FREE_ALL_DIRECT_REQUEST';
export const FREE_ALL_DIRECT_SENT = 'migration/FREE_ALL_DIRECT_SENT';
export const FREE_ALL_DIRECT_SUCCESS = 'migration/FREE_ALL_DIRECT_SUCCESS';
export const FREE_ALL_DIRECT_FAILURE = 'migration/FREE_ALL_DIRECT_FAILURE';

export const REMOVE_ALLOWANCE_PROXY_REQUEST =
  'migration/REMOVE_ALLOWANCE_PROXY_REQUEST';
export const REMOVE_ALLOWANCE_PROXY_SENT =
  'migration/REMOVE_ALLOWANCE_PROXY_SENT';
export const REMOVE_ALLOWANCE_PROXY_SUCCESS =
  'migration/REMOVE_ALLOWANCE_PROXY_SUCCESS';
export const REMOVE_ALLOWANCE_PROXY_FAILURE =
  'migration/REMOVE_ALLOWANCE_PROXY_FAILURE';

// Actions ------------------------------------------------

const handleTx = ({ prefix, dispatch, txObject, successPayload = '' }) =>
  new Promise(resolve => {
    const txMgr = window.maker.service('transactionManager');
    txMgr.listen(txObject, {
      pending: tx => {
        dispatch({
          type: `migration/${prefix}_SENT`,
          payload: { txHash: tx.hash }
        });
      },
      mined: async _ => {
        dispatch({
          type: `migration/${prefix}_SUCCESS`,
          payload: successPayload
        });

        resolve(true);
      },
      error: (_, err) => {
        dispatch({ type: `migration/${prefix}_FAILURE`, payload: err });
        dispatch(addToastWithTimeout(ToastTypes.ERROR, err));
        resolve(false);
      }
    });
  });

export const freeAllProxy = () => async (dispatch, getState) => {
  const account = getAccount(getState(), window.maker.currentAddress());

  const free = window.maker.service('voteProxy').freeAll(account.proxy.address);

  dispatch({ type: FREE_ALL_PROXY_REQUEST });
  return handleTx({
    prefix: 'FREE_ALL_PROXY',
    dispatch,
    txObject: free
  }).then(success => success && dispatch(addAccounts([account])));
};

export const freeAllDirect = () => async (dispatch, getState) => {
  const account = getAccount(getState(), window.maker.currentAddress());
  const deposists = await window.maker
    .service('chief')
    .getNumDeposits(account.address);

  const free = window.maker.service('chief').free(deposists);

  dispatch({ type: FREE_ALL_DIRECT_REQUEST });
  return handleTx({
    prefix: 'FREE_ALL_DIRECT',
    dispatch,
    txObject: free
  }).then(success => success && dispatch(addAccounts([account])));
};

export const removeAllMkrAllowance = () => async (dispatch, getState) => {
  const currentAddress = window.maker.currentAddress();
  const account = getAccount(getState(), currentAddress);
  const proxyAddress = account.proxy.address;

  const removeAllowance = window.maker.getToken('MKR').approve(proxyAddress, 0);

  dispatch({ type: REMOVE_ALLOWANCE_PROXY_REQUEST });
  return handleTx({
    prefix: 'REMOVE_ALLOWANCE_PROXY',
    dispatch,
    txObject: removeAllowance
  }).then(success => {
    if (success) {
      // refetch account info
      dispatch(addAccounts([account]));
      setTimeout(() => {
        // if the value hasn't been updated after 1 sec, we fetch again
        if (getAccount(getState(), currentAddress).proxy.hasInfMkrApproval)
          dispatch(addAccounts([account]));
      }, 1000);
    }
  });
};

// Reducer ------------------------------------------------

const initialState = {
  freeAllProxyTxHash: '',
  freeAllDirectTxHash: '',
  removeAllowanceProxyTxHash: '',
  freeAllProxyTxStatus: TransactionStatus.NOT_STARTED,
  freeAllDirectTxStatus: TransactionStatus.NOT_STARTED,
  removeAllowanceProxyTxStatus: TransactionStatus.NOT_STARTED
};

const migration = createReducer(initialState, {
  [FREE_ALL_DIRECT_REQUEST]: state => ({
    ...state,
    freeAllDirectTxHash: '',
    freeAllDirectTxStatus: TransactionStatus.NOT_STARTED
  }),
  [FREE_ALL_DIRECT_SENT]: (state, { payload }) => ({
    ...state,
    freeAllDirectTxStatus: TransactionStatus.PENDING,
    freeAllDirectTxHash: payload.txHash
  }),
  [FREE_ALL_DIRECT_SUCCESS]: state => ({
    ...state,
    freeAllDirectTxStatus: TransactionStatus.MINED
  }),
  [FREE_ALL_DIRECT_FAILURE]: state => ({
    ...state,
    freeAllDirectTxStatus: TransactionStatus.ERROR
  }),
  // --------------------------------------------
  [FREE_ALL_PROXY_REQUEST]: state => ({
    ...state,
    freeAllProxyTxHash: '',
    freeAllProxyTxStatus: TransactionStatus.NOT_STARTED
  }),
  [FREE_ALL_PROXY_SENT]: (state, { payload }) => ({
    ...state,
    freeAllProxyTxStatus: TransactionStatus.PENDING,
    freeAllProxyTxHash: payload.txHash
  }),
  [FREE_ALL_PROXY_SUCCESS]: state => ({
    ...state,
    freeAllProxyTxStatus: TransactionStatus.MINED
  }),
  [FREE_ALL_PROXY_FAILURE]: state => ({
    ...state,
    freeAllProxyTxStatus: TransactionStatus.ERROR
  }),
  // --------------------------------------------
  [REMOVE_ALLOWANCE_PROXY_REQUEST]: state => ({
    ...state,
    removeAllowanceProxyTxHash: '',
    removeAllowanceProxyTxStatus: TransactionStatus.NOT_STARTED
  }),
  [REMOVE_ALLOWANCE_PROXY_SENT]: (state, { payload }) => ({
    ...state,
    removeAllowanceProxyTxStatus: TransactionStatus.PENDING,
    removeAllowanceProxyTxHash: payload.txHash
  }),
  [REMOVE_ALLOWANCE_PROXY_SUCCESS]: state => ({
    ...state,
    removeAllowanceProxyTxStatus: TransactionStatus.MINED
  }),
  [REMOVE_ALLOWANCE_PROXY_FAILURE]: state => ({
    ...state,
    removeAllowanceProxyTxStatus: TransactionStatus.ERROR
  })
});

export default migration;
